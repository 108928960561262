<template>
  <div class="g-section job-container">
    <div class="g-wrap job-top">
      <div class="flex">
        <!-- <span>筛选</span> -->
        <!-- <el-input placeholder="请输入关键字查询" suffix-icon="el-icon-search" v-model="keyword" size="small">
        </el-input> -->
      </div>
      <el-button type="primary" @click="$router.push('/school/achievementShow/create')" size="middle" icon="el-icon-circle-plus-outline">发布成果
      </el-button>
    </div>
    <div v-if="list.length>0" class="g-wrap job-bottom" v-loading="loading">
      <div v-for="(item, i) in list" class="job-item" :key="i">
        <div class="item-top">
          <div class="item-left">
            <!-- <span class="item-text">{{item.Headline}}</span> -->
            <p class="item-text">{{ item.Name }}</p>
            <p v-html="item.Description"></p>
          </div>
          <!-- <div class="item-right">
            <div class="right-btn" ><span>0人</span><span>已报名</span></div>
            <div class="right-btn"><span>3位</span><span>已面试</span></div>
            <div class="right-btn"><span>0人</span><span>已通过</span></div>
          </div> -->
        </div>
        <div class="item-bottom">
          <div class="item-left">
            <span>发布时间：{{ item.PublishTime }}</span>
          </div>
          <div class="item-right">
            <el-button type="text" icon="el-icon-search" size="small" @click="$router.push(`/school/achievementShow/detail?id=${item.Id}`)">查看</el-button>
            <el-button type="text" icon="el-icon-edit" size="small" @click="$router.push(`/school/achievementShow/edit?id=${item.Id}`)">编辑</el-button>
            <el-button type="text" icon="el-icon-delete" size="small" @click="handleDelete(item.Id, i)">删除</el-button>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="g-wrap job-bottom nodata">
       <el-image style="width: 240px; height: 180px" :src="require('@/assets/images/nodata.png')" />
       <span class="mt-18">暂无数据</span>
    </div>
    <div class="loading">
      <div v-if="loading">加载中...</div>
      <!-- <div v-else-if="noMore" class="nomore-text">—— 没有更多了 ——</div> -->
      <el-button v-else-if="!noMore" @click="load" type="text">加载更多</el-button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { getPageList, deleteEntity } from "@/api/achievement";
import { StaffTypeEnum } from "@/utils/constant";
export default {
  data() {
    return {
      list: [],
      keyword: "",
      loading: true,
      pageIndex: 0,
      pageSize: 5,
      totalCount: 0,
      SchoolId: ""
    };
  },
  computed: {
    ...mapGetters(["employee"]),
    noMore() {
      return this.loading == false && this.list.length == this.totalCount;
    }
  },
  created() {
    const {
      Employer: { Id }
    } =
      this.employee.find(
        ({ StaffType }) => StaffType === StaffTypeEnum.School
      ) || {};
    this.SchoolId = Id;
    this.load();
  },
  methods: {
    load() {
      this.loading = true;
      this.pageIndex++;
      var queryData = {
        KeyWord: this.KeyWord,
        PageSize: this.pageSize,
        PageIndex: this.pageIndex
        // SchoolId: this.SchoolId
      };
      getPageList(queryData).then(({ Result: { Data, TotalCount } }) => {
        console.log(Data);
        this.list = this.list.concat(Data);
        this.totalCount = TotalCount;
        this.loading = false;
      });
    },
    search() {
      this.loading = true;
      this.pageIndex = 1;
      var queryData = {
        KeyWord: this.keyword,
        PageSize: this.pageSize,
        PageIndex: this.pageIndex,
        SchoolId: this.SchoolId
      };
      console.log("search", queryData);
      getPageList(queryData).then(({ Result: { Data, TotalCount } }) => {
        this.list = Data;
        this.totalCount = TotalCount;
        this.loading = false;
      });
    },
    handleDelete(id, index) {
      this.$confirm("删除后无法恢复，确认删除？", "温馨提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          deleteEntity(id).then(_ => {
            this.list.splice(index, 1);
            if (this.totalCount > 0) {
              this.totalCount--;
            }
            this.$message({
              message: "删除成功",
              type: "success"
            });
          });
        })
        .catch(_ => { });
    }
  }
};
</script>
<style lang="scss" scoped>
.job-container {
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background: #f5f5f5;
}
.job-top {
  justify-content: space-between;
  margin-top: 20px;
}
.g-wrap {
  background: #fff;
}
.g-wrap.job-top {
  padding: 24px 24px 16px;
  border-bottom: 1px solid #eeeeee;
  // flex-direction: column;
}
.g-wrap.job-bottom {
  padding: 16px 24px;
  margin-bottom: 20px;
}
.job-bottom {
  display: flex;
  flex-direction: column;
  // margin-top: 20px;
  border-radius: 0px 0px 8px 8px;
  min-height: 30vh;
}
.job-item {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
  margin-bottom: 16px;
  border: 1px solid #e7e8ea;
  border-radius: 3px;
  .item-top {
    padding: 10px;
    display: flex;
    justify-content: space-between;
    .item-left {
      display: flex;
      flex-direction: column;
      justify-content: center;
      // align-items: center;
      .item-text {
        font-size: 18px;
        font-weight: 600;
      }
    }
    .item-right {
      display: flex;
      align-items: center;
      .right-btn {
        cursor: pointer;
        margin-left: 20px;
        display: flex;
        flex-direction: column;
      }
    }
  }
  .item-bottom {
    justify-content: space-between;
    display: flex;
    border-top: 1px solid #e7e8ea;
    background: #eeeeee;
    height: 32px;
    align-items: center;
    padding: 8px 10px;
  }
  .el-button {
    padding: 0px;
  }
}
.text {
  color: #999999;
}
.nodata {
  flex: 1;
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  box-sizing: border-box;
  span {
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #999999;
    line-height: 22px;
  }
}
</style>
